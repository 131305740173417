body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ccc; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #F14E95; /* Thumb color */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}